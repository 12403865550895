import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button } from 'components'
import { useParams } from 'react-router'
import { useSdk } from 'sdk'
import { useHeader } from 'mobile/Header'
import { DataTable } from 'src/sdk/tw/DataTable'
import { Column } from 'react-table'
import { RouterOutput, trpc } from 'src/trpc'
import { StickyHeader } from '../styles'
import SearchBar from 'components/SearchBar/SearchBar'
import { icons } from 'components/Icon'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { ListHeader } from 'layout/ListHeader'
import { MobileContext } from 'src/Context'
import {
  getValidIteratorsOptions,
  getLoyaltyListName,
  formatPriceNumberWithDelimiter,
  resolveFullName,
} from 'src/helpers'
import { TabContainer } from 'src/styles'
import { TabView, Tab } from 'components'
import { useFooter } from 'mobile/Footer'
import { getOrganizationByIdGql } from 'src/state/query/getOrganizationById'
import { useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { Select } from 'src/components/Select2/Select'
import { CheckMarkIcon } from 'src/components/Icon/Icons/Checkmark'
import { SelectRow, SelectOptionLabel } from 'src/views/AppointmentModal/styles'

export type TypeGiftCardItem = {
  description: string | null
  discountRate: any
  giftCardTemplateId: bigint
  id: bigint
  itemName: string | null
  serviceId: bigint
  serviceTypeId: bigint
  status: 'ACTIVE' | 'DELETED'
  quantity: number
  Service?: any
  ServiceType?: any
}

export const TemplateList = () => {
  const params = useParams<{
    selectedLocationId: string
    orgId: string
    voucherType: string
  }>()
  const { selectedLocationId, orgId, voucherType } = params
  const { navigateTo, t } = useSdk()
  const isMobile = useContext(MobileContext)

  const [filterText, setFilterText] = useState<string>('')
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    voucherType === 'giftcard' ? 0 : 1
  )

  const [statusFilter, setStatusFilter] = useState<
    { id: string; name: string }[]
  >([
    { id: 'ACTIVE', name: t('translation.GiftCardList.status-active') },
    { id: 'INACTIVE', name: t('translation.GiftCardList.status-inactive') },
  ])

  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
    }
  )
  const org = organizationData?.organization?.get

  const queryInput = {
    orgId: orgId,
    locationId: BigInt(selectedLocationId),
    voucherType: [voucherType?.toUpperCase()],
    searchTerm: filterText,
    status: statusFilter?.map(x => x?.id),
  }

  const { data, isLoading } =
    trpc.giftCardTemplate_findMany.useQuery(queryInput)

  const dataList = data?.sort((a, b) => Number(b?.id) - Number(a?.id)) || []

  const trpcContext = trpc.useContext()

  useEffect(() => {
    setSelectedTabIndex(voucherType === 'giftcard' ? 0 : 1)
    trpcContext.giftCardTemplate_findMany.invalidate()
  }, [voucherType])

  useEffect(() => {
    navigateTo.listTemplates({
      voucherType: selectedTabIndex === 0 ? 'giftcard' : 'voucher',
    })
  }, [selectedTabIndex])

  useHeader(
    {
      content: getLoyaltyListName(voucherType, 'template'),
      onGoBack: navigateTo.mobileBranchSettings,
    },
    [voucherType]
  )

  useFooter(
    {
      showAddButton: true,
      hasTitle: false,
      addButtonOptions: [
        {
          onClick: () => {
            navigateTo.newTemplate({ type: voucherType })
          },
          title: 'Dodaj predložak',
          icon: <icons.Add size="smaller" />,
        },

        {
          component: (
            <>
              <span className="text-lg font-semibold mt-[20px]">
                {'Vrste predložaka'}
              </span>
              <div className="flex flex-col pl-[20px]">
                <span
                  className=" mt-[20px]"
                  onClick={() =>
                    navigateTo.listTemplates({ voucherType: 'giftcard' })
                  }
                >
                  Poklon bonovi
                </span>
                <span
                  className=" my-[10px]"
                  onClick={() =>
                    navigateTo.listTemplates({ voucherType: 'voucher' })
                  }
                >
                  Paketi
                </span>
              </div>
            </>
          ),
          onClick: () => {},
        },
        null as any,
      ],
    },
    [voucherType]
  )

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'image',
        minWidth: 50,
        width: 50,

        Cell: props => {
          const imageConfig = JSON.parse(
            props.cell.row.original?.giftCardImageConfig || 'null'
          )
          return (
            <div className="mr-3">
              <img
                src={
                  imageConfig?.fullImageId
                    ? `${
                        import.meta.env.VITE_API_URL
                      }/api/giftCardTemplate/${props.cell.row.original?.id}/${
                        imageConfig.fullImageId
                      }`
                    : '/assets/noImage.svg'
                }
              ></img>
            </div>
          )
        },
      },
      {
        Header: 'Naziv',
        accessor: 'name',
        minWidth: 200,
        width: 200,
        Cell: props => <p>{props.cell.row.original?.name}</p>,
      },
      {
        Header: 'Usluge',
        accessor: 'services',
        minWidth: 250,
        width: 250,
        Cell: props => (
          <div className="mr-[10px]">
            {props.cell.row.original?.items?.length ? (
              <span className="line-clamp-3 text-[12px]">
                {props.cell.row.original?.items?.map((item, idx) => {
                  return (
                    item?.itemName +
                    (idx < props.cell.row.original?.items?.length - 1
                      ? ', '
                      : '')
                  )
                })}
              </span>
            ) : (
              <span className="line-clamp-3 text-[12px]">{'Sve usluge'}</span>
            )}
          </div>
        ),
      },
      {
        Header: () => <div className="pr-[20px] text-end">{'Cijena'}</div>,
        accessor: 'price',
        minWidth: 100,
        width: 100,
        Cell: props => (
          <p className="pr-[20px] text-end">{`${formatPriceNumberWithDelimiter(
            props.cell.row.original?.price
          )} ${org?.currency?.id}`}</p>
        ),
      },
      {
        Header: () => <div className="text-center">{'Valjanost'}</div>,
        accessor: 'validAmount',
        minWidth: 100,
        width: 100,
        Cell: props => (
          <p className="text-center">
            {`${props.cell.row.original?.validAmount} ${
              getValidIteratorsOptions()?.find(
                x => x?.id === props.cell.row.original?.validIterator
              )?.name || ''
            }`}
          </p>
        ),
      },
      {
        Header: () => <div className="text-center">{'Online prodaja'}</div>,
        accessor: 'onlinePurchaseEnabled',
        minWidth: 80,
        width: 80,
        Cell: props => (
          <p className="text-center">
            {props.cell.row.original?.onlinePurchaseEnabled ? (
              <CheckMarkIcon size="smaller" />
            ) : null}
          </p>
        ),
      },
    ],

    [org]
  )

  const MobileOption = props => {
    const isSelected = props.selectProps.value?.find(
      e => e.id === props.data?.id
    )
    return (
      <SelectRow>
        <span className="mr-[0.571rem] h-[1.143rem] w-[1.143rem] flex justify-center text-zoyya-text relative bottom-[5px] shrink-0">
          {isSelected && <CheckMarkIcon color="grayDarker" />}
        </span>

        <SelectOptionLabel data-cy={'mobileSelect_value'}>
          {props.data?.title}
        </SelectOptionLabel>
      </SelectRow>
    )
  }

  const selectComponents = {
    MobileOption,
  }

  const ShowList = () => {
    return (
      <div className="flex flex-col overflow-y-auto w[100%] p-[0] lg:p-4 h-[calc(100vh-55px)]">
        {!isMobile ? (
          <div className="flex w-full items-center sticky top-0 z-10 pb-3">
            <StickyHeader>
              <Button
                onClick={() => navigateTo.newTemplate({ type: voucherType })}
                iconComponent={<icons.Add size="smaller" stroke={2} />}
                buttonType={'primary'}
                label={'Novi predložak'}
                name="addNew"
              />
            </StickyHeader>
            <SearchBar
              messageList={false}
              clientsList={false}
              search={filterText}
              onChange={search => setFilterText(search?.toLowerCase())}
              isOpen
              placeholder={t('translation.Table.label-search')}
              styleWrapper={{
                marginRight: 10,
                marginLeft: 10,
              }}
            />
          </div>
        ) : (
          <div>
            <ListHeader
              hasSearch
              onSearchChange={search => setFilterText(search?.toLowerCase())}
              messageList
              clientsList
              styleWrapper={{ top: '0' }}
              placeholder={t('translation.Table.label-search')}
            />
          </div>
        )}

        <div className="flex flex-1 pt-1">
          <div className="flex flex-row p-0 m-0 w-full">
            <DataTable
              data={dataList}
              renderMobileCard={(item: any) => (
                <GiftCardMobileCard item={item} />
              )}
              columns={columns}
              isLoading={isLoading || loadingOrgData}
              emptyListTitle={'Nema predloška'}
              onRowClick={item => navigateTo.editTemplate({ id: item?.id })}
              mobileCardHeight={100}
              tableRowHeight={70}
            />
          </div>
        </div>
      </div>
    )
  }

  if (isMobile)
    return (
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-col flex-1 lg:p-4">{ShowList()}</div>
      </div>
    )

  return (
    <TabContainer>
      <TabView
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
        isSingleTab={false}
        hasFooter={false}
      >
        <Tab
          hasFooter={false}
          title={t('translation.Sidebar.label-giftcardsTemplates')}
        >
          {ShowList()}
        </Tab>

        <Tab
          hasFooter={false}
          title={t('translation.Sidebar.label-voucherTemplates')}
        >
          {ShowList()}
        </Tab>
      </TabView>
    </TabContainer>
  )
}

const GiftCardMobileCard = (props: {
  item: RouterOutput['giftCardTemplate_findMany'][0]
}) => {
  const { item } = props
  const imageConfig = JSON.parse(item?.giftCardImageConfig || 'null')
  return (
    <div className="flex h-full flex-row py-4 mx-4 border-b border-zoyya-outline items-center">
      {imageConfig?.fullImageId ? (
        <img
          className="h-[40px] w-fit mr-3"
          src={
            imageConfig?.fullImageId
              ? `${
                  import.meta.env.VITE_API_URL
                }/api/giftCardTemplate/${item?.id}/${imageConfig.fullImageId}`
              : '/assets/noImage.svg'
          }
        ></img>
      ) : null}

      <div className="flex flex-col flex-1">
        <div className="flex justify-between">
          <p className="line-clamp-1  mr-2">{item?.name}</p>
          <span>{item?.status}</span>
        </div>

        <div className="text-gray-400 mb-2 text-sm">
          {item?.items?.length ? (
            <span className="line-clamp-2 text-[12px]">
              {item?.items?.map((x, idx) => {
                return x?.itemName + (idx < item?.items?.length - 1 ? ', ' : '')
              })}
            </span>
          ) : (
            'Sve usluge'
          )}
        </div>

        {item?.onlinePurchaseEnabled ? (
          <div className="ml-auto text-gray-500">
            <CheckMarkIcon size="extraSmall" />
            <span className="ml-1">Online</span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default TemplateList
